var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "storeRoomForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitUrl: _vm.submitUrl,
              submitText: "保存",
              submitBefore: _vm.submitBefore,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c("div", { staticClass: "cell-title" }, [
            _c("span", { staticClass: "pre-point" }),
            _vm._v("基本信息："),
          ]),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "用户房产信息",
                rules: [
                  {
                    required: true,
                    message: "请选择用户房产信息",
                    trigger: "change",
                  },
                ],
                prop: "houseId",
              },
            },
            [
              _c(
                "v-select2",
                _vm._b(
                  {
                    attrs: { width: 400, echoRequestData: _vm.echohouseId },
                    on: { onChange: _vm.houseIdChange },
                    model: {
                      value: _vm.form.houseId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "houseId", $$v)
                      },
                      expression: "form.houseId",
                    },
                  },
                  "v-select2",
                  _vm.houseParams,
                  false
                )
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "库房地址",
                rules: [
                  {
                    required: true,
                    message: "请选择库房地址",
                    trigger: "change",
                  },
                ],
                prop: "storeroomHouseId",
              },
            },
            [
              _c(
                "v-select2",
                _vm._b(
                  {
                    attrs: { width: 400 },
                    on: { onChange: _vm.storeroomChange },
                    model: {
                      value: _vm.form.storeroomHouseId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "storeroomHouseId", $$v)
                      },
                      expression: "form.storeroomHouseId",
                    },
                  },
                  "v-select2",
                  _vm.storeroomParams,
                  false
                )
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "状态",
                rules: [
                  { required: true, message: "请选择状态", trigger: "change" },
                ],
                prop: "status",
              },
            },
            [
              _c("v-select", {
                attrs: { options: _vm.statusOps },
                model: {
                  value: _vm.form.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "status", $$v)
                  },
                  expression: "form.status",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "库房编号", prop: "storageRoomNumber" } },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.storageRoomNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "storageRoomNumber", $$v)
                  },
                  expression: "form.storageRoomNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "完整编号", prop: "storageRoomFullNumber" } },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.storageRoomFullNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "storageRoomFullNumber", $$v)
                  },
                  expression: "form.storageRoomFullNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "起止时间" } },
            [
              _c("v-datepicker", {
                attrs: {
                  startTime: _vm.form.handleTime,
                  endTime: _vm.form.expireTime,
                  type: "rangedate",
                },
                on: {
                  "update:startTime": function ($event) {
                    return _vm.$set(_vm.form, "handleTime", $event)
                  },
                  "update:start-time": function ($event) {
                    return _vm.$set(_vm.form, "handleTime", $event)
                  },
                  "update:endTime": function ($event) {
                    return _vm.$set(_vm.form, "expireTime", $event)
                  },
                  "update:end-time": function ($event) {
                    return _vm.$set(_vm.form, "expireTime", $event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注" } },
            [
              _c("v-textarea", {
                attrs: { type: "textarea", maxlength: 200 },
                model: {
                  value: _vm.form.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }