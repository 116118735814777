<template>
  <div class="storeRoomForm-container">
    <form-panel ref="formPanel" :form="form"  v-bind="submitConfig" :submitUrl="submitUrl" submitText="保存" :submitBefore="submitBefore">
        <div class="cell-title"><span class="pre-point"></span>基本信息：</div>
        <el-form-item label="用户房产信息" :rules="[{ required: true, message: '请选择用户房产信息', trigger: 'change' }]" prop="houseId">
          <v-select2 :width="400" v-model="form.houseId" v-bind="houseParams" @onChange="houseIdChange" :echoRequestData="echohouseId"></v-select2>
        </el-form-item>
        <el-form-item label="库房地址" :rules="[{ required: true, message: '请选择库房地址', trigger: 'change' }]" prop="storeroomHouseId">
          <v-select2 :width="400" v-model="form.storeroomHouseId" v-bind="storeroomParams" @onChange="storeroomChange"></v-select2>
        </el-form-item>
        <el-form-item label="状态" :rules="[{ required: true, message: '请选择状态', trigger: 'change' }]" prop="status">
          <v-select v-model="form.status" :options="statusOps"></v-select>
        </el-form-item>
        <el-form-item label="库房编号" prop="storageRoomNumber">
          <v-input v-model="form.storageRoomNumber"></v-input>
        </el-form-item>
        <el-form-item label="完整编号" prop="storageRoomFullNumber">
          <v-input v-model="form.storageRoomFullNumber"></v-input>
        </el-form-item>

        <el-form-item label="起止时间">
          <v-datepicker :startTime.sync="form.handleTime" :endTime.sync="form.expireTime" type="rangedate" />
        </el-form-item>
        <el-form-item label="备注">
          <v-textarea v-model="form.remark" type="textarea" :maxlength="200"></v-textarea>
        </el-form-item>
    </form-panel>
  </div>
</template>
<script>
import { getStoreRoomUserListUrl, getStoreroomListURL, getStoreRoomDetailUrl, storeRoomUserUploadUrl } from './api'
export default {
  data () {
    return {
      form: {
        userId: '',
        houseId: '',
        communityId: '',
        storeroomHouseId: '',
        handleTime: '',
        expireTime: '',
        status: 0,
        remark: '',
        storageRoomNumber: '',
        storageRoomFullNumber: ''
      },
      submitConfig: {
        submitContentType: 'application/json'
      },
      submitUrl: storeRoomUserUploadUrl,
      houseParams: {
        searchUrl: getStoreRoomUserListUrl,
        request: {
          text: 'ownerName',
          value: 'houseId'
        },
        response: {
          text: 'name',
          value: 'houseId'
        },
        method: 'get'
      },
      storeroomParams: {
        searchUrl: getStoreroomListURL,
        request: {
          text: 'roomNum',
          value: 'houseId'
        },
        response: {
          text: 'name',
          value: 'id'
        },
        method: 'get'
      },
      statusOps: [{
        text: '正常',
        value: 0
      }, {
        text: '关闭',
        value: 1
      }]
    }
  },
  created () {
    let formId = this.$route.query.id
    let userId = this.$route.query.userId
    let houseId = this.$route.query.houseId
    if (formId) {
      this.$setBreadcrumb('编辑')
      this.getFormDetail(formId)
    } else if (userId && houseId) {
      this.$setBreadcrumb('新增')
      this.setUser(userId, houseId)
    } else {
      this.$setBreadcrumb('新增')
    }
  },
  methods: {
    submitBefore () {
      return true
    },
    houseIdChange (val) {
      val && (this.form.userId = val.id)
    },
    storeroomChange (val) {
      val && (this.form.communityId = val.communityId)
    },
    getFormDetail (id) {
      this.$axios.get(getStoreRoomDetailUrl+`?id=${id}`).then((res) => {
        if (res.status == 100) { // eslint-disable-line
          let data = res.data
          this.form = {
            userId: data.userId,
            houseId: data.houseId,
            communityId: data.communityId,
            storeroomHouseId: data.storeroomHouseId,
            handleTime: data.handleTime ? data.handleTime : '',
            expireTime: data.expireTime ? data.expireTime : '',
            status: data.status,
            remark: data.remark ? data.remark : '',
            storageRoomNumber: data.storageRoomNumber ? data.storageRoomNumber : '',
            storageRoomFullNumber: data.storageRoomFullNumber ? data.storageRoomFullNumber : '',
            id
          }
        }
      })
    },
    setUser (userId, houseId) {
      this.$set(this.form, 'userId', userId)
      this.$set(this.form, 'houseId', houseId)
    },
    echohouseId (data) {
      let newData = {
        userId: `{"userId":"${this.form.userId}","houseId":"${data.houseId}"}`
      }
      return newData
    }
  }
}
</script>
<style lang="scss" scoped>
.storeRoomForm-container {
  background: #fff;
  text-align: left;
  padding-left: 20px;
}
.cell-title{
  text-align: left;
  padding-left: 10px;
  margin: 15px 0;
  display: flex;
  align-items: center;
  .pre-point {
    // display: inline-block;
    border-radius: 50%;
    background-color: #dce2e7;
    height: 5px;
    width: 5px;
    margin-right: 5px;
  }
}
</style>
